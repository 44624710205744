import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PubSub from "pubsub-js";
import Zoom from "./map/Zoom";
import Infos from "./Infos";
import Menu from "./Menu";
import LocaleSwitcher from "./ui/LocaleSwitcher";
import SummaryDetail from "./ui/SummaryDetail";
// import Plus from "../images/plus.inline.svg";
import { navigate } from "gatsby";
import { _localizeField, _localizeText } from "../core/utils";

const HeaderDesktop = ({ navTags, sanityInfos }) => {
  const [open, setOpen] = useState(true);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const tokenStatus = PubSub.subscribe("POI_STATUS", (e, PoiIsOpen) => {
      // console.log(e, PoiIsOpen);
      if (PoiIsOpen) setOpen(false);
    });

    const tokenClose = PubSub.subscribe("OVERLAY_CLOSE", () => {
      setOpen(false);
    });

    const tokenRoute = PubSub.subscribe("ROUTE_UPDATE", (e) => {
      if (!ready) return;
      // console.log(e, ready);
      setOpen(false);
    });

    setTimeout(() => {
      setReady(true);
    }, 250);

    return () => {
      PubSub.unsubscribe(tokenStatus);
      PubSub.unsubscribe(tokenClose);
      PubSub.unsubscribe(tokenRoute);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _toggleInfos = () => {
    if (!open) {
      navigate("/");
      setTimeout(() => {
        setOpen(true);
      }, 150);
    } else {
      setOpen(false);
    }
  };

  return (
    <div className='inner pointer-events-auto overflow-y-auto max-h-screen'>
      <div className='p-sm pb-0 md:p-lg'>
        <div className=' grid grid-cols-2 md:block'>
          <div className='header-item mb-xl md:mb-xs col-span-1 pr-[6px] md:p-0'>
            <Zoom />
          </div>

          <div className='header-item mb-xl md:mb-xs col-span-1 pl-[6px] md:p-0'>
            <button
              className={clsx(
                "p-md- md:py-sm- md:px-lg- py-md px-20 md:py-sm block text-center bg-white ui-border-o ui-border-o--rounded w-full",
                open
                  ? "bg-black border-black text-white "
                  : "bg-white text-black"
              )}
              tabIndex='0'
              onKeyPress={() => _toggleInfos()}
              onClick={() => _toggleInfos()}>
              <span>{_localizeField(sanityInfos.buttonTitle)}</span>
              {/* <span>{open ? " open" : ""}</span> */}
            </button>
          </div>

          <Infos input={sanityInfos} isOpen={open} />
          <Menu input={navTags} />

          <div className=' ui-border-o bg-white mb-sm-'>
            <SummaryDetail summary={_localizeText("language")}>
              <LocaleSwitcher />
            </SummaryDetail>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDesktop;
