import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { getCookieConsentValue } from "react-cookie-consent";
import { subscribe, unsubscribe } from "pubsub-js";
const Container = styled.div`
  iframe {
    aspect-ratio: 16 / 9;
  }
`;

const VideoPlayer = ({ input, playing }) => {
  const [granted, setGranted] = useState();

  useEffect(() => {
    const cookieConsent = getCookieConsentValue();
    // console.log(Cookies);
    // console.log(cookieConsent);
    if (cookieConsent) setGranted(true);

    const token = subscribe("COOKIE_CONSENT_ACCEPT", () => setGranted(true));

    return () => unsubscribe(token);
  }, []);
  // let videoUrl =
  //   input.indexOf("youtube") > -1
  //     ? input.replace("youtube", "youtube-nocookie")
  //     : "";
  // if (input.indexOf("youtu") > -1)
  //   videoUrl = input.replace("youtu.be", "youtube-nocookie.com");
  // const [status, setStatus] = useState({
  //   muted: false,
  //   // playing: playing,
  // });
  // console.log(status);
  const player = useRef();

  const config = {
    youtube: {
      playerVars: {
        controls: 1,
        disablekb: 1,
        enablejsapi: 1,
        iv_load_policy: 3,
        modestbranding: 1,
        cc_load_policy: 0,
        showinfo: 0,
        rel: 0,
        origin: "https://dialogue-works.netlify.app",
      },
      embedOptions: {
        host: "https://www.youtube-nocookie.com",
      },
    },
    // vimeo: {
    //   title: false,
    //   background: true,
    //   controls: false,
    // },
  };

  const _onReady = () => {};
  const _onEnded = () => {};

  return (
    <Container className='video-player w-full' ref={player}>
      {granted && (
        <ReactPlayer
          className={`player w-full `}
          url={input}
          playing={playing}
          // loop={true}
          muted={false}
          playsinline
          config={config}
          width='100%'
          height='auto'
          onReady={_onReady}
          onEnded={_onEnded}
        />
      )}
    </Container>
  );
};

export default VideoPlayer;
