import React, { useEffect, useState } from "react";
// import L from "leaflet";
import PubSub from "pubsub-js";
import clsx from "clsx";
import Plus from "../../images/plus.inline.svg";
import Minus from "../../images/minus.inline.svg";

const Zoom = () => {
  const [zoom, setZoom] = useState();

  useEffect(() => {
    const token = PubSub.subscribe("ZOOM.CHANGE", (e, d) => {
      setZoom(d);
    });

    return () => PubSub.unsubscribe(token);
  }, []);

  const _zoomIn = () => {
    PubSub.publish("ZOOM.IN");
  };

  const _zoomOut = () => {
    PubSub.publish("ZOOM.OUT");
  };

  return (
    <div className='bg-white ui-border-o px-lg  py-md md:p-sm md:px-[20px] box-o'>
      <div className='flex justify-between items-center'>
        <button
          className='minus relative'
          onClick={_zoomOut}
          aria-label='zoom out'>
          <span
            className={clsx(
              "icon icon-o absolute x-y-centered",
              zoom === 2 ? "disabled" : ""
            )}>
            <Minus />
          </span>
        </button>
        <div className='title leading-none'>Zoom</div>
        <button
          className={clsx("plus relative")}
          onClick={_zoomIn}
          aria-label='zoom in'>
          <span
            className={clsx(
              "icon icon-o absolute x-y-centered",
              zoom === 4 ? "disabled" : ""
            )}>
            <Plus />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Zoom;
