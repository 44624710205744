import React, { useState, useEffect } from "react";
import L from "leaflet";
import { Marker, useMapEvents } from "react-leaflet";
// import { urlFor } from "../../core/sanity";
import PubSub from "pubsub-js";
import { navigate } from "gatsby";
import useTags from "../../contexts/TagsWrapper";
import DivIcon from "./DivIcon";
import ReactDOMServer from "react-dom/server";
import { _localizeField, _linkResolver } from "../../core/utils";

const MarkerSvg = ({ input, initialZoom }) => {
  // console.log(input);
  // const [svg, setSvg] = useState(null);
  const [iconsByZoom, setIconsByZoom] = useState([]);
  // const [zoom, setZoom] = useState(initialZoom);
  const { title, notInteractive, tags, icons, geoloc } = input;
  const latlng = geoloc.split(",");
  const { tag } = useTags();

  const map = useMapEvents({
    zoom: () => {
      // setZoom(map.getZoom());
    },
  });

  useEffect(() => {
    _loadSvgs().then((results) => {
      setIconsByZoom(results);
    });

    const token = PubSub.subscribe("ROUTE_UPDATE", (e) => {
      if (window.location.pathname === _linkResolver(input)) _centerToMarker();
    });

    return () => PubSub.unsubscribe(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let amap = async (arr, fun) =>
    await Promise.all(arr.map(async (v, i) => await fun(v, i)));

  const fetchSvgMarkup = async (url) => {
    const headers = {
      // mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };
    const response = await fetch(url, headers);
    const text = await response.text();
    return text;
  };

  const _loadSvgs = async () => {
    const zoomLevels = [4, 3, 2];
    const _icons = await amap(icons, async (item, i) => {
      const markup = await fetchSvgMarkup(item.svg.asset.url);
      const sizes = item.sizes
        ? item.sizes.split(",")
        : [
            item.svg.asset.metadata.dimensions.width,
            item.svg.asset.metadata.dimensions.height,
          ];
      // console.log(sizes);
      return {
        zoom: zoomLevels[i],
        svgMarkup: markup,
        width: sizes[0],
        height: sizes[1],
      };
    });
    return _icons;
  };
  // console.log(icons);

  const _getIsInFilter = () => {
    const isFiltering = tag && Object.keys(tag).length !== 0;
    if (isFiltering) {
      const isIn = tags.filter((el) => el.slug.current === tag.slug.current);
      return isIn.length > 0;
    } else {
      return true;
    }
  };

  const _getIsCurrentPoi = () => {
    if (typeof window !== "undefined") {
      const location = window.location.pathname;
      // console.log(location, _linkResolver(input));
      return location === _linkResolver(input);
    }
  };

  const _isIn = _getIsInFilter();
  const _isCurrentPoi = _getIsCurrentPoi();

  useEffect(() => {
    const token = PubSub.subscribe("MAP_CENTER", () => {
      if (notInteractive) {
        map.setView(latlng);
      }
    });
    return () => PubSub.unsubscribe(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getDivIcon = () => {
    return L.divIcon({
      className: `custom-icon ${notInteractive ? "pointer-events-none" : ""} ${
        _isCurrentPoi ? "is-current-poi" : ""
      }`,
      html: ReactDOMServer.renderToString(
        <DivIcon
          title={_localizeField(title)}
          icons={iconsByZoom}
          isIn={notInteractive ? true : _isIn}
          isCurrentPoi={_isCurrentPoi}
        />
      ),
    });
  };

  const _onClick = () => {
    // console.log(window.location);
    // const location = window.location.pathname.slice(1);
    if (_getIsCurrentPoi()) {
      PubSub.publish("POI.OPEN", input);
    } else {
      navigate(_linkResolver(input));
    }

    _centerToMarker();
  };

  const _centerToMarker = () => {
    setTimeout(() => {
      // map.setView(latlng, 4);
      map.setView(latlng);
    }, 250);
  };

  return (
    <div className='marker-svg'>
      <Marker
        interactive={notInteractive ? false : true}
        position={latlng}
        icon={_getDivIcon()}
        className='test'
        eventHandlers={{
          click: (e) => {
            _onClick();
          },
        }}></Marker>
    </div>
  );
};

export default MarkerSvg;
