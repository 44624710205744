import React from "react";
import TexteUI from "./TexteUI";
import ImageUI from "./ImageUI";
import VideoUI from "./VideoUI";
import styled from "styled-components";
import clsx from "clsx";

const Container = styled.div`
  section:last-child {
    margin-bottom: 0 !important;
  }
`;

const Modules = ({ input, layout }) => {
  // console.log(input);
  const _renderModules = () => {
    const _modules = input.map((module, i) => {
      // console.log(module);
      switch (module._type) {
        case "texteUI":
          return module.texte ? (
            <TexteUI key={module._key} input={module.texte} />
          ) : null;
        case "imageUI":
          return module.image ? (
            <ImageUI key={module._key} input={module} />
          ) : null;
        case "videoUI":
          return <VideoUI key={i} input={module} />;
        default:
          return null;
      }
    });
    return _modules;
  };

  return <Container className={clsx("modules ")}>{_renderModules()}</Container>;
};

export default Modules;
