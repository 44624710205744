import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PubSub from "pubsub-js";
import Zoom from "./map/Zoom";
import Infos from "./Infos";
import Menu from "./Menu";
import LocaleSwitcher from "./ui/LocaleSwitcher";
import SummaryDetail from "./ui/SummaryDetail";
import Plus from "../images/plus.inline.svg";
import { _localizeText, _localizeField } from "../core/utils";
import Credits from "./Credits";

const HeaderMobile = ({ navTags, sanityInfos }) => {
  const [open, setOpen] = useState(false);
  const [infosOpen, setInfosOpen] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  // const [ready, setReady] = useState(false);

  useEffect(() => {
    const tokenInfosClose = PubSub.subscribe("INFOS_CLOSE", () => {
      setInfosOpen(false);
    });

    const tokenClose = PubSub.subscribe("OVERLAY_CLOSE", (e) => {
      // console.log(e);
      setOpen(false);
      setInfosOpen(false);
    });

    const tokenRoute = PubSub.subscribe("ROUTE_UPDATE", (e) => {
      setOpen(false);
      // if (!ready) return;
      setInfosOpen(false);
    });

    // setTimeout(() => {
    //   setReady(true);
    // }, 250);

    return () => {
      PubSub.unsubscribe(tokenInfosClose);
      PubSub.unsubscribe(tokenClose);
      PubSub.unsubscribe(tokenRoute);
    };
  }, []);

  useEffect(() => {
    if (open) {
      document.documentElement.classList.add("header-mobile-open");
      // setInfosOpen(true);
    } else document.documentElement.classList.remove("header-mobile-open");

    // PubSub.publish("INFOS_STATUS", open);
  }, [open]);
  // console.log(sanityInfos);
  return (
    <div className='inner pointer-events-auto overflow-y-auto max-h-screen'>
      <div className='p-sm pb-0 '>
        <div
          className={clsx(
            "header-toggle  grid-cols-2",
            open ? "hidden" : "grid"
          )}>
          <div className='header-item mb-xl col-span-1 pr-[6px] '>
            <Zoom />
          </div>

          <div className='header-item mb-xl col-span-1 pl-[6px] '>
            <button
              className={clsx(
                "py-md px-26 block text-center bg-white hover:bg-black hover:border-black hover:text-white ui-border-o ui-border-o--rounded w-full",
                open
                  ? "bg-black border-black text-white "
                  : "bg-white text-black"
              )}
              tabIndex='0'
              onKeyPress={() => setOpen(true)}
              onClick={() => setOpen(true)}>
              {_localizeField(sanityInfos.buttonTitle)}
            </button>
          </div>
        </div>

        <div className={clsx("mobile-nav ", open ? "block " : "")}>
          <div
            className={clsx(
              "infos-toggle ui-border-o bg-white mb-sm p-md w-full relative",
              infosOpen ? "hidden" : "block"
            )}
            role='button'
            tabIndex='0'
            onKeyPress={() => setInfosOpen(true)}
            onClick={() => setInfosOpen(true)}>
            <div className='flex justify-between'>
              <div className='pr-md ellipsis'>
                {_localizeField(sanityInfos.buttonTitleAlt)}
              </div>
              <div className='icon-container w-[24px] shrink-0'>
                <div className='absolute right-0 top-1/2 -translate-y-1/2 icon'>
                  <Plus />
                </div>
              </div>
            </div>
          </div>

          <Infos input={sanityInfos} isOpen={infosOpen} />
          <Menu input={navTags} />

          <div className='ui-border-o bg-white mb-sm'>
            <SummaryDetail summary={_localizeText("language")}>
              <LocaleSwitcher />
            </SummaryDetail>
          </div>

          <div className='ui-border-o bg-white p-md '>
            <ul className=''>
              {sanityInfos.links.map((li, i) => (
                <li
                  key={i}
                  className={clsx(
                    "mb-xs"
                    // i < sanityInfos.links.length - 1 ? "mb-xs" : "mb-1e-"
                  )}>
                  {li._type === "externalLink" && (
                    <a
                      href={li.url}
                      target='_blank'
                      rel='noopener, noreferrer'
                      className='pill inline-block outline outline--small py-sm px-md md:py-xxs md:px-xs '>
                      {_localizeField(li.label)}
                    </a>
                  )}
                </li>
              ))}
              <li className='pr-xs mb-xs- md:px-xxs'>
                <button
                  className={clsx(
                    "pill outline outline--small py-sm px-md md:py-xxs md:px-xs",
                    showCredits ? "is-active" : ""
                  )}
                  onClick={() => setShowCredits(!showCredits)}>
                  Credits
                </button>
              </li>
            </ul>
            <Credits showCredits={showCredits} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMobile;
