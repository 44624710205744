import React, { createContext, useState, useContext } from "react";

const TagsContext = createContext();
const initialTagsState = {};

export const TagsWrapper = ({ children }) => {
  const [tag, dispatchTag] = useState(initialTagsState);

  return (
    <TagsContext.Provider value={{ tag, dispatchTag }}>
      {children}
    </TagsContext.Provider>
  );
};
export default function useTags() {
  return useContext(TagsContext);
}
// export { TagsContext, TagsWrapper }
