import React from "react";
import PubSub from "pubsub-js";
import { _localizeText } from "../core/utils";

const Footer = () => (
  <footer className='px-sm  fixed bottom-0 left-0 w-full z-20  sm-only'>
    <div className='p-md pb-xl inner bg-white'>
      <button
        onClick={() => PubSub.publish("OVERLAY_CLOSE")}
        className='py-lg px-26 block text-center btn-lg bg-black text-white ui-border-o ui-border-o--rounded w-full'>
        {_localizeText("backToMap")}
      </button>
    </div>
  </footer>
);

export default Footer;
