import React from "react";
// import { Link } from "gatsby";
import { _localizeField } from "../core/utils";
import SummaryDetail from "./ui/SummaryDetail";
import Tag from "./Tag";
import clsx from "clsx";
import styled from "styled-components";

const Nav = styled.nav`
  .summary-detail {
    &:not(.is-opened) button.summary,
    .detail {
      border-bottom: var(--border);
    }
  }
  .menu-group:last-child .summary-detail {
    &:not(.is-opened) button.summary,
    .detail {
      border: none;
    }
  }
`;

const Menu = ({ input }) => {
  return (
    <Nav className={clsx("ui-border-o bg-white mb-sm md:mb-xs")}>
      <ul className=''>
        {input.map((item, i) => (
          <li key={i} className='menu-group'>
            {item._type === "tagGroup" && (
              <SummaryDetail
                summary={_localizeField(item.group.title)}
                id={`header-summary-detail-${i}`}>
                <ul className='tags '>
                  {item.tags.map((tag, j) => (
                    <li
                      key={j}
                      className={clsx(
                        "menu-item ",
                        j < item.tags.length - 1 ? "mb-xs" : "mb-1e"
                      )}>
                      <Tag input={tag} />
                    </li>
                  ))}
                </ul>
              </SummaryDetail>
            )}
          </li>
        ))}
      </ul>
    </Nav>
  );
};

export default Menu;
