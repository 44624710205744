import clsx from "clsx";
import React, { useState } from "react";
import { _localizeField } from "../core/utils";
import Credits from "./Credits";
import Modules from "./modules";

const ContentInfos = ({ input }) => {
  const { title, modules, links } = input;
  const [showCredits, setShowCredits] = useState(false);

  return (
    <article>
      <div className='header text-center mb-sm md:mb-xs bg-white ui-border-o text-lg'>
        <div className='headline px-lg py-1e flex flex-col justify-center'>
          <h1 className=' '>{_localizeField(title)}</h1>
        </div>
      </div>

      <div className='content mb-sm md:mb-xs'>
        <Modules input={modules} />
      </div>

      <div
        className={clsx(
          "ui-border-o mb-sm md:mb-xs bg-white p-md md:p-lg  md:text-sm- hidden-sm",
          showCredits ? "pb-0 md:pb-0" : ""
        )}>
        <ul className='flex md:justify-center flex-wrap'>
          {links.map((li, i) => (
            <li key={i} className='pr-xs mb-xs md:px-xxs'>
              {/* {li._type === "internalLink" && (
                <Link
                  to={_linkResolver(li.link)}
                  className='pill outline outline--small py-sm px-md'>
                  {_localizeField(li.label)}
                </Link>
              )} */}
              {li._type === "externalLink" && (
                <a
                  href={li.url}
                  target='_blank'
                  rel='noopener, noreferrer'
                  className='pill block outline outline--small py-sm px-md md:py-xxs- md:px-xs- '>
                  {_localizeField(li.label)}
                </a>
              )}
            </li>
          ))}
          <li className='pr-xs mb-xs- md:px-xxs'>
            <button
              className={clsx(
                "pill outline outline--small py-sm px-md",
                showCredits ? "is-active" : ""
              )}
              onClick={() => setShowCredits(!showCredits)}>
              Credits
            </button>
          </li>
        </ul>
        <Credits showCredits={showCredits} />
      </div>
    </article>
  );
};

export default ContentInfos;
