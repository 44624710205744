// import ReactDOM from "react-dom";
import React, { useEffect, createContext } from "react";
// import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import Map from "./map/Map";
import { TagsWrapper } from "../contexts/TagsWrapper";
import CookieConsent from "./ui/CookieConsent";
// import { publish } from "pubsub-js";

const WrapperContext = createContext();

// const query = graphql`
//   query {
//     settings: prismicSettings {
//       ...settings
//     }
//   }
// `;

const Layout = ({ children, pageContext, location }) => {
  const settings = {};

  useEffect(() => {
    _format();

    window.addEventListener("resize", _format);
    document.addEventListener("keyup", _onKeyboard);

    return () => {
      window.removeEventListener("resize", _format);
      document.removeEventListener("keyup", _onKeyboard);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log(location);
  //   if (location.state && location.state.openInfos) {
  //     console.log(location.state);

  //     publish("INFOS_OPEN");
  //   }
  // }, [location]);

  const _format = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    );

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    );
  };

  const _onKeyboard = (e) => {
    // console.log(e);
    if (e.key === "d") {
      document.querySelector("#debug").classList.toggle("hidden");
    }
  };

  return (
    <WrapperContext.Provider value={{ settings }}>
      <CookieConsent />
      <TagsWrapper>
        <div id='page'>
          <Header />
          <div className='fixed top-0 left-0 h-screen w-screen'>
            <Map />
          </div>
          <main className='min-h-screen pointer-events-none'>{children}</main>
          <Footer />
        </div>
      </TagsWrapper>
    </WrapperContext.Provider>
  );
};

export { WrapperContext, Layout };
