import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  get_youtube_video_thumbnail_by_url,
  _localizeField,
} from "../../core/utils";
import Play from "../../images/play.inline.svg";
import VideoPlayer from "../ui/VideoPlayer";
import SanityTexte from "../SanityTexte";

const Caption = styled.div`
  border-top: var(--border);
`;

const VideoUI = ({ input }) => {
  const [playing, setPlaying] = useState(false);
  const [isOnline, setIsOnLine] = useState(true);

  // const playerRef = useRef();
  // const videoUrl = input.url
  //   ? input.url.replace("youtube", "youtube-nocookie")
  //   : "";
  // console.log(videoUrl);

  useEffect(() => {
    // console.log(navigator.onLine);
    setIsOnLine(navigator.onLine);
    window.addEventListener("online", () => setIsOnLine(true));
    window.addEventListener("offline", () => setIsOnLine(false));
    // playerRef.current.addEventListener("ended", (event) => {
    //   playerRef.current.currentTime = 0;
    //   setPlaying(false);
    // });
  }, []);

  const _onClick = () => {
    if (!playing) setPlaying(!playing);
  };

  // console.log(input);
  // if (!input.url) return null;
  const thumbnail = input.videoEmbedOrFile.videoEmbed.en
    ? get_youtube_video_thumbnail_by_url(input.videoEmbedOrFile.videoEmbed.en)
    : "";
  // const videoUrl = isOnline
  //   ? _localizeField(input.videoEmbedOrFile.videoEmbed)
  //   : _localizeField(input.videoEmbedOrFile?.videoFile)?.asset.url;
  const videoUrl = _localizeField(input.videoEmbedOrFile.videoEmbed);
  // console.log(input.videoEmbedOrFile?.videoFile);
  return (
    <section
      className='module-video-ui mb-sm md:mb-xs relative ui-border-o overflow-hidden bg-white'
      aria-hidden
      onKeyDown={() => _onClick()}
      onClick={() => _onClick()}>
      <div
        className='inner cursor-pointer'
        style={{
          aspectRatio: "560/315",
        }}>
        {videoUrl && <VideoPlayer input={videoUrl} playing={playing} />}

        {!playing && (
          <div
            className='preview absolute inset-0 z-0'
            style={{
              backgroundImage: `url(${thumbnail})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              // backgroundColor: "red",
            }}>
            <div
              className='absolute left-1/2 top-1/2 cursor-pointer -translate-x-1/2 -translate-y-1/2 icon icon-o play bg-white'
              role='button'>
              <Play />
            </div>
          </div>
        )}
      </div>
      {input.caption && (
        <Caption className=' px-md md:px-lg block text-left text-sm'>
          <SanityTexte input={_localizeField(input.caption)} />
        </Caption>
      )}
    </section>
  );
};

export default VideoUI;
