import React from "react";
import styled from "styled-components";
import clsx from "clsx";

const Container = styled.div`
  .svg {
    display: none;
  }
  .zoom-4 & .svg-zoom-4 {
    display: block;
  }
  .zoom-3 & .svg-zoom-3 {
    display: block;
  }
  .zoom-2 & .svg-zoom-2 {
    display: block;
  }
  &.is-not-in {
    svg {
      path:not(#shadow) {
        fill: var(--color-floor);
        stroke: var(--color-black);
        stroke-width: 1px;
      }
      path#shadow {
        fill: var(--color-black);
      }
    }
  }

  &.is-current-poi .title-wrap,
  &:hover .title-wrap {
    opacity: 1;
  }
  svg {
    width: 100%;
    path {
      transition: fill 0.4s ease;
    }
  }

  .title-wrap {
    max-width: 180px;
    transform: translateX(-50%) translateY(calc(-100% + var(--space-xs)));
  }
`;

const DivIcon = ({ isIn, isCurrentPoi, title, icons }) => {
  // console.log(isCurrentPoi);

  return (
    <Container
      className={clsx(
        "marker-div-icon absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
        !isIn ? "is-not-in" : "",
        isCurrentPoi ? "is-current-poi" : ""
      )}>
      <div className='marker-icon w-full-'>
        {icons.map((icon, i) => (
          <div
            className={`svg absolute- svg-zoom-${icon.zoom}`}
            key={i}
            style={{ width: `${icon.width}px`, height: `${icon.height}px` }}>
            <div
              dangerouslySetInnerHTML={{
                __html: icon.svgMarkup,
              }}></div>
          </div>
        ))}
      </div>

      <div className='title-wrap absolute left-1/2 top-1/2- top-0- -top-md -translate-x-1/2- -translate-y-2/2- -translate-y-full- flex justify-center items-center opacity-0 transition-opacity z-10 pointer-events-none'>
        <div className='title  whitespace-nowrap  outline bg-white py-xxs px-xs ui-border-o ui-border-o--rounded ellipsis'>
          {title}
        </div>
      </div>
    </Container>
  );
};

export default DivIcon;
