import clsx from "clsx";
import React, { useEffect, useState } from "react";
import useTags from "../contexts/TagsWrapper";
import { _localizeField } from "../core/utils";

const Tag = ({ input }) => {
  const { tag, dispatchTag } = useTags();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!tag.slug) {
      setActive(false);
      return;
    }
    setActive(tag.slug.current === input.slug.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  const _onClick = () => {
    if (active) {
      dispatchTag({});
    } else {
      dispatchTag(input);
    }
  };

  return (
    <div className='tag-item'>
      <button
        className={clsx(
          "tag outline py-sm px-md",
          // tag.slug
          //   ? tag.slug.current === input.slug.current
          //     ? "is-active"
          //     : ""
          //   : ""
          active ? "is-active" : ""
        )}
        onClick={() => _onClick()}>
        {_localizeField(input.title)}
      </button>
    </div>
  );
};

export default Tag;
