// utils/useDeviceDetect.js
import { useEffect, useState } from "react";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    _onResize();
    window.addEventListener("resize", _onResize);

    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobile(mobile);

    return () => window.removeEventListener("resize", _onResize);
  }, []);

  const _onResize = () => {
    setMobile(window.innerWidth < 1080);
    // setMobile(window.innerWidth < 991);
  };

  useEffect(() => {
    if (isMobile) {
      document.documentElement.classList.remove("no-touch");
      document.documentElement.classList.add("touch");
    } else {
      document.documentElement.classList.remove("touch");
      document.documentElement.classList.add("no-touch");
    }
  }, [isMobile]);

  return { isMobile };
}
