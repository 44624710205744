import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

import sanityConfig from "../../config/sanity";

const SanityImage = ({ input, alt = "", showPlaceholder = true }) => {
  // const [imageLoaded, setImageloaded] = useState(false)
  // return null;
  // if (!input) return null;

  // console.log(input);
  const { asset } = input;
  const { metadata } = asset;
  if (!metadata) return null;

  const width = 1500;
  const height = width / metadata.dimensions.aspectRatio;
  // const imagePlaceholder = getImage(asset);
  const imageData = getGatsbyImageData(
    asset,
    {
      width: width,
      height: height,
      fit: "FILLMAX",
      // placeholder: "BLURRED",
    },
    sanityConfig.sanity
  );
  // console.log(imageData);

  return (
    <figure className='relative'>
      {showPlaceholder && (
        <div
          className='absolute bg-placeholder w-full h-full'
          style={{
            backgroundColor: metadata.palette.dominant.background,
          }}></div>
      )}
      <div>
        <GatsbyImage image={imageData} alt={alt} />
      </div>
    </figure>
  );
};

export default SanityImage;
