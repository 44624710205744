import React, { useEffect, createContext, useContext } from "react";
import { useState } from "react";

const LocaleContext = createContext();

export const LocaleWrapper = ({ children }) => {
  const [locale, dispatch] = useState("en");

  //detect user lang
  useEffect(() => {
    const userLang = _detectUserLang();
    // console.log("userLang:", userLang);
    if (userLang === "es") {
      dispatch(userLang);
    } else {
      dispatch("en");
    }
  }, []);

  const _detectUserLang = () => {
    const userLang = navigator.language || navigator.userLanguage;
    return userLang.toLowerCase();
  };

  return (
    <LocaleContext.Provider value={{ locale, dispatch }}>
      {children}
    </LocaleContext.Provider>
  );
};

// export default LocaleWrapper;
// export { LocaleContext, LocaleWrapper };

export default function useLocale() {
  return useContext(LocaleContext);
}
