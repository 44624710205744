/* eslint react/prop-types: 0, react/display-name: 0 */
import React from "react";
import Helmet from "react-helmet";
import PubSub from "pubsub-js";
import { Layout } from "./src/components/Layout";
import { LocaleWrapper } from "./src/contexts/LocaleWrapper";
// import { withPrefix } from 'gatsby'
// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

// export default wrapPageElement

const _wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        {/* <script src='https://unpkg.com/pace-js@1.0.2/pace.min.js'></script> */}
        <script src='https://cdnjs.cloudflare.com/ajax/libs/pace/1.2.4/pace.min.js'></script>
      </Helmet>
      {element}
    </>
  );
};

const _wrapPageElement = ({ element, props }) => {
  // console.log(Layout)
  return (
    <LocaleWrapper>
      <Layout {...props}>{element}</Layout>
    </LocaleWrapper>
  );
};

const _onClientEntry = () => {
  //console.log("We've started!")
  // callAnalyticsAPI()
  const isTouch =
    "ontouchstart" in window ? true : false || window.innerWidth < 1080;

  if (isTouch) {
    document.documentElement.classList.add("touch");
  } else {
    document.documentElement.classList.add("no-touch");
  }

  setTimeout(() => {
    PubSub.publish("ROUTE_UPDATE");
  }, 150);
};

const _onRouteUpdate = ({ location }) => {
  PubSub.publish("ROUTE_UPDATE");
};

export { _wrapRootElement, _wrapPageElement, _onClientEntry, _onRouteUpdate };
