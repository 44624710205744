// import { useContext } from "react";
import locales from "../../config/i18n";
import useLocale from "../contexts/LocaleWrapper";

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf("/") + 1);
}

export const _slugify = (raw) => raw.replace(/ /g, "-").toLowerCase();

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const _getLocale = () => {
  const { locale } = useLocale();
  return locale;
};

export function _localizeField(field) {
  // console.log(field);
  const { locale } = useLocale();
  if (!field) return "";
  return field && field[locale] ? field[locale] : field["en"];
}

export const _localizeText = (text) => {
  const { locale } = useLocale();
  return locales[locale] && locales[locale][text]
    ? locales[locale][text]
    : text;
};

export const _linkResolver = (node) => {
  if (!node || !node.slug) return "/";
  switch (node._type) {
    case "poi":
      // console.log(node);
      return `/poi/${node.slug.current}`;
    default:
      return `/${node.slug.current}`;
  }
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const get_youtube_video_id_by_url = (url) => {
  // var regExp =
  //   /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  // var match = url.match(regExp);
  // return match && match[7].length === 11 ? match[7] : false;
  const reg =
    /^(https?:)?(\/\/)?((www\.|m\.)?youtube(-nocookie)?\.com\/((watch)?\?(feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w\-]{10,20})/i;
  const match = url.match(reg);
  if (match) {
    return match[9];
  } else {
    return null;
  }
};

export const get_youtube_video_thumbnail_by_url = (url) => {
  // console.log(url);
  const ID = get_youtube_video_id_by_url(url);
  return `https://i.ytimg.com/vi/${ID}/hqdefault.jpg`;
};
