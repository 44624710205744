//const website = require('./website')

module.exports = {
  en: {
    default: true,
    label: "English",
    path: "en",
    locale: "en",
    siteLanguage: "en",
    ogLang: "en_GB",
    projects: "Projects",
    about: "About",
    contact: "Contact",
    backToMap: "Back to map",
    language: "Language",
    cookieButtonText: "It's okay.",
    cookieDeclineButtonText: "No, thanks.",
    cookieMessage:
      "We use cookies to enhance the user experience. Learn more in our <a href='https://www.dialogue-works.com/campaign/' target='_blank'>privacy settings.</a>",
  },
  // de: {
  //   default: false,
  //   label: "Deutsch",
  //   path: "de",
  //   locale: "de",
  //   siteLanguage: "de",
  //   ogLang: "de_DE",
  //   projects: "Projets",
  //   about: "À propos",
  //   contact: "Contact",
  //   backToMap: "Zurück zur Karte",
  //   language: "Sprache",
  // },
  es: {
    default: false,
    label: "Español",
    path: "es",
    locale: "es",
    siteLanguage: "es",
    ogLang: "es_ES",
    projects: "Projects",
    about: "About",
    contact: "Contact",
    backToMap: "Volver al mapa",
    language: "Idioma",
    cookieButtonText: "Está bien.",
    cookieDeclineButtonText: "No, thanks.",
    cookieMessage:
      "We use cookies to enhance the user experience. Learn more in our <a href='https://www.dialogue-works.com/campaign/' target='_blank'>privacy settings.</a>",
  },
};
