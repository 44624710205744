import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { MapContainer, TileLayer, useMapEvents, useMap } from "react-leaflet";
import L from "leaflet";
import MarkerSvg from "./MarkerSvg";

import PubSub from "pubsub-js";
import "leaflet/dist/leaflet.css";

const query = graphql`
  query {
    allSanityPoi {
      nodes {
        ...poiCard
      }
    }
  }
`;

const Bounds = ({ coords, rendered }) => {
  const group = L.featureGroup();
  const map = useMap();

  useEffect(() => {
    if (rendered) return;
    if (!map) return;

    group.clearLayers();

    coords.forEach((marker) => group.addLayer(L.marker(marker)));

    map.fitBounds(group.getBounds());
    map.setView([0, 0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, coords]);

  return null;
};

const MarkerGroup = ({ input, initialZoom }) => {
  const map = useMapEvents({
    click(e) {
      if (document.getElementById("lat-lng")) {
        document.getElementById(
          "lat-lng"
        ).innerText = `lat : ${e.latlng.lat.toFixed(
          2
        )}, lng: ${e.latlng.lng.toFixed(2)}`;
      }
    },
    zoom: () => {
      const zoom = map.getZoom();

      PubSub.publish("ZOOM.CHANGE", zoom);

      document.getElementById("map").className = `zoom-${zoom}`;

      if (document.getElementById("zoom-level")) {
        document.getElementById("zoom-level").innerText = `zoom : ${zoom}`;
      }
    },
  });

  return (
    <>
      {input.map((item, i) => (
        <MarkerSvg key={i} input={item} initialZoom={initialZoom} />
      ))}
    </>
  );
};

const ZoomHandler = () => {
  const map = useMap();

  useEffect(() => {
    const zoomInToken = PubSub.subscribe("ZOOM.IN", (e) => {
      map.zoomIn();
      // PubSub.publish("ZOOM.CHANGE", map.getZoom());
    });

    const zoomOutoken = PubSub.subscribe("ZOOM.OUT", (e) => {
      map.zoomOut();
      // PubSub.publish("ZOOM.CHANGE", map.getZoom());
    });

    return () => {
      PubSub.unsubscribe(zoomInToken);
      PubSub.unsubscribe(zoomOutoken);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const Map = () => {
  const {
    allSanityPoi: { nodes },
  } = useStaticQuery(query);

  const [zoom, setZoom] = useState(4);
  const [rendered, setRendered] = useState(false);
  const coords = nodes.map((el) => el.geoloc.split(","));

  useEffect(() => {
    const zoomToken = PubSub.subscribe("ZOOM.CHANGE", (e, d) => {
      // console.log(e, d);
      setZoom(d);
    });

    return () => {
      PubSub.unsubscribe(zoomToken);
    };
  }, [setZoom]);

  if (typeof window !== "undefined") {
    return (
      <div className='' id='map'>
        <div className='z-20 h-screen'>
          <MapContainer
            center={[0, 0]}
            // zoom={zoom}
            doubleClickZoom={true}
            scrollWheelZoom={false}
            touchZoom={false}
            zoomAnimation={false}
            zoomControl={false}
            whenReady={() => setRendered(true)}
            style={{ height: "100vh", background: "#FEFBE4" }}>
            <TileLayer
              attribution='&copy; <a href="https://aeai.eu">aeai</a>'
              url='/tiles/{z}/{x}/{y}.jpg'
              continuousWorld={false}
              noWrap={true}
              // unloadInvisibleTiles={true}
              minZoom={2}
              maxZoom={4}
            />
            <MarkerGroup input={nodes} initialZoom={zoom} />
            <Bounds coords={coords} rendered={rendered} />
            <ZoomHandler />
          </MapContainer>
        </div>
        <div
          id='debug'
          className=' fixed left-md bottom-md z-50 text-black text-right hidden'>
          <div id='zoom-level'>zoom : {zoom}</div>
          <div id='lat-lng'></div>
        </div>
      </div>
    );
  }
  return null;
};

export default Map;
