import React, { useState, useEffect } from "react";
// import { navigate } from "gatsby";
import clsx from "clsx";
import styled from "styled-components";
import { unsubscribe, subscribe, publish } from "pubsub-js";
import ContentInfos from "./ContentInfos";
import Plus from "../images/plus.inline.svg";
import useDeviceDetect from "../hooks/useDeviceDetect";

const Panel = styled.div`
  @media all and (max-width: 1080px) {
    display: none;
    &.is-open {
      display: block;
    }
  }
  @media all and (min-width: 1080px) {
    position: fixed;
    right: 0;
    top: 0;
    // width: 360px;
    opacity: ${(props) => (props.open ? "1" : "1")};
    transform: ${(props) =>
      props.open ? "translateX(0%)" : "translateX(100%)"};
  }
`;

const Infos = ({ input, isOpen }) => {
  const [open, setOpen] = useState(true);
  const { isMobile } = useDeviceDetect();
  // console.log(open, isOpen);

  useEffect(() => {
    const tokenStatus = subscribe("POI_STATUS", (e, PoiIsOpen) => {
      // console.log(e);
      if (PoiIsOpen) setOpen(false);
    });

    // const tokenOpen = subscribe("INFOS_OPEN", (e) => {
    //   // console.log(e);
    //   // setOpen(true);
    // });

    //update state
    const tokenClose = subscribe("POI_CLOSE", (e) => {
      // console.log(e);
      setOpen(false);
    });

    const tokenRoute = subscribe("ROUTE_UPDATE", (e) => {
      // console.log(e);
      if (!isOpen) setOpen(false);
    });

    return () => {
      unsubscribe(tokenStatus);
      unsubscribe(tokenClose);
      // unsubscribe(tokenOpen);
      unsubscribe(tokenRoute);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (open) {
      publish("MAP_CENTER");
      document.documentElement.classList.add("infos-open");
    } else {
      document.documentElement.classList.remove("infos-open");
    }

    publish("INFOS_STATUS", open);
  }, [open]);

  const _close = () => {
    // console.log("close");
    setOpen(false);
    if (isMobile) publish("INFOS_CLOSE");
    else publish("OVERLAY_CLOSE");
    // setTimeout(() => {
    //   navigate("/");
    // }, 150);
  };

  return (
    <div className='infos'>
      <Panel
        className={clsx(
          "infos-panel transition-all duration-500 ease-in",
          open ? "is-open" : ""
        )}
        open={open}>
        <div className='inner md:overflow-y-auto  md:max-h-screen mb-sm'>
          <div className='  md:p-lg'>
            <ContentInfos input={input} />
          </div>
          <div className='close-wrapper absolute right-[5px] top-0 md:right-lg md:top-lg z-10 p-sm'>
            <button
              className='close '
              onClick={() => _close()}
              aria-label='close'>
              <span className='icon icon-o close'>
                <Plus />
              </span>
            </button>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default Infos;
