import React from "react";
// import clsx from "clsx";
import styled from "styled-components";
import { _localizeField } from "../../core/utils";
import SanityImage from "../SanityImage";
import SanityTexte from "../SanityTexte";
import Plus from "../../images/plus.inline.svg";

import { publish } from "pubsub-js";

const Caption = styled.div`
  border-top: var(--border);
`;

const ImageUI = ({ input }) => {
  const { image, title, caption, expandable } = input;

  const _onClick = () => {
    publish("IMAGE_MODAL", image);
  };
  // console.log(expandable);
  return (
    <section className='module-image-ui mb-sm md:mb-xs overflow-hidden ui-border-o bg-white relative'>
      <div className='image-wrap'>
        {expandable && (
          <>
            <SanityImage input={image} alt={_localizeField(title)} />
            <div
              className='open-wrapper absolute right-0 top-0 z-10 p-sm'
              tabIndex={0}
              role='button'
              onKeyDown={_onClick}
              onClick={_onClick}>
              <button className='_open ' aria-label='open image'>
                <span className='icon icon-o open'>
                  <Plus />
                </span>
              </button>
            </div>
          </>
        )}
        {!expandable && (
          <SanityImage input={image} alt={_localizeField(title)} />
        )}
      </div>
      {caption && (
        <Caption className='px-md md:px-lg block text-left text-sm'>
          <SanityTexte input={_localizeField(caption)} />
        </Caption>
      )}
    </section>
  );
};

export default ImageUI;
