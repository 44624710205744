import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";
// import Burger from "./ui/Burger";

const query = graphql`
  query {
    sanityHeader {
      navTags {
        ... on SanityTagGroup {
          _type
          group {
            title {
              ...localeString
            }
          }
          tags {
            slug {
              current
            }
            title {
              ...localeString
            }
          }
        }
      }
    }
    sanityInfos {
      buttonTitle {
        ...localeString
      }
      buttonTitleAlt {
        ...localeString
      }
      title {
        ...localeString
      }
      # texte: _rawTexte(resolveReferences: { maxDepth: 10 })
      modules {
        ... on SanityTexteUI {
          _key
          _type
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityImageUI {
          _key
          _type
          caption: _rawCaption(resolveReferences: { maxDepth: 10 })
          image: _rawImage(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityVideoUI {
          _key
          _type
          url
          video {
            asset {
              url
            }
          }
          caption: _rawCaption(resolveReferences: { maxDepth: 10 })
        }
      }
      links {
        ... on SanityInternalLink {
          _type
          label {
            ...localeString
          }
          link {
            ... on SanityPage {
              _type
              title {
                ...localeString
              }
              slug {
                current
              }
            }
            ... on SanityPoi {
              _type
              title {
                ...localeString
              }
              slug {
                current
              }
            }
          }
        }
        ... on SanityExternalLink {
          _type
          label {
            ...localeString
          }
          url: link
        }
      }
    }
  }
`;

const Header = () => {
  const {
    sanityHeader: { navTags },
    sanityInfos,
  } = useStaticQuery(query);

  return (
    <header className='fixed bottom-0 md:top-0 w-full md:w-[228px] z-10 pointer-events-none'>
      <div className='sm-only'>
        <HeaderMobile navTags={navTags} sanityInfos={sanityInfos} />
      </div>
      <div className='hidden-sm'>
        <HeaderDesktop navTags={navTags} sanityInfos={sanityInfos} />
      </div>
    </header>
  );
};

export default Header;
