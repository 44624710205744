import React, { useState } from "react";
import clsx from "clsx";
import styled from "styled-components";
// import PubSub from "pubsub-js";
import { _localizeText } from "../../core/utils";
import Plus from "../../images/plus.inline.svg";

const DropDownButton = styled.button`
  text-align: left;
  position: relative;
`;
const DropDownContent = styled.div`
  width: 100%;
  overflow: hidden;
  // max-height: 0;
  // transition: max-height 0.1s, opacity 0.1s; // note that we're transitioning max-height, not height!
  // transition-timing-function: ease;
  height: auto;
  // max-height: 350px; // still have to hard-code a value!
  &.is-collapsed {
    // max-height: 0;
    height: 0;
    opacity: 0;
    border-bottom: none;
    padding: 0;
  }
`;

const SummaryDetail = ({ summary, id, children }) => {
  // const [active, setActive] = useState()
  const [collapsed, setCollapsed] = useState(true);

  // useEffect(() => {
  //   const token = PubSub.subscribe(
  //     "SUMMARY_DETAIL.TOGGLE",
  //     (e, { collapsed, from }) => {
  //       console.log(collapsed, from);
  //       if (id !== from) setCollapsed(true);
  //     }
  //   );

  //   return () => PubSub.unsubscribe(token);
  // }, []);

  // useEffect(() => {
  //   // console.log(collapsed);
  //   if (!collapsed) {
  //     PubSub.publish("SUMMARY_DETAIL.TOGGLE", {
  //       collapsed: collapsed,
  //       from: id,
  //     });
  //   }
  // }, [collapsed]);

  // const _className = active ? "is-active" : ""

  return (
    <div
      className={clsx(`summary-detail`, !collapsed ? "is-opened " : "")}
      id={id}>
      <DropDownButton
        className='summary p-md md:py-sm md:px-xs w-full relative'
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}>
        <div className='flex justify-between'>
          <div className='pr-md md:pr-xs ellipsis'>
            {_localizeText(summary)}
          </div>
          <div className={clsx("icon-container w-[24px] shrink-0")}>
            <div
              className={clsx(
                "absolute right-0 top-1/2 -translate-y-1/2 icon",
                !collapsed ? "close icon-o" : ""
              )}>
              <Plus />
            </div>
          </div>
        </div>
      </DropDownButton>
      <DropDownContent
        className={clsx(
          "detail pb-1e- px-md md:px-xs",
          collapsed ? "is-collapsed " : ""
        )}>
        {children}
      </DropDownContent>
    </div>
  );
};

export default SummaryDetail;
