import React from "react";
import SanityTexte from "../SanityTexte";
import { _localizeField } from "../../core/utils";
// import styled from "styled-components";

// const Container = styled.section`
//   h2{
//     padding: 1em 0;
//   }
//   p{
//     marging
//   }
// `

const TexteUI = ({ input }) => {
  return (
    <section className='module-texte-ui portable-text mb-sm md:mb-xs px-md md:px-lg bg-white ui-border-o'>
      <SanityTexte input={_localizeField(input)} />
    </section>
  );
};

export default TexteUI;
