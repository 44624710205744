import React from "react";
import PortableText from "@sanity/block-content-to-react";
import { Link } from "gatsby";
import { _linkResolver } from "../core/utils";
// import SanityImage from "./SanityImage";
// import VideoEmbed from "./ui/VideoEmbed";

const SanityTexte = ({ input }) => {
  // console.log(input);
  const serializers = {
    types: {
      block(props) {
        // console.log(props.node.style);
        switch (props.node.style) {
          case "h2":
            return <h2 className='text-md capitalize'>{props.children}</h2>;
          case "text_lg":
            return <p className='text-lg'>{props.children}</p>;
          case "text-sm":
            return <p className='text-sm'>{props.children}</p>;
          default:
            return <p className='text-md'>{props.children}</p>;
        }
      },
      // image: ({ node }) => {
      //   return <SanityImage input={node} />;
      // },
      // videoEmbed: ({ node }) => {
      //   return <VideoEmbed input={node.url} />;
      // },
    },

    marks: {
      align_center: ({ children }) => (
        <span className='text-center'>{children}</span>
      ),
      sup: ({ children }) => <sup>{children}</sup>,
      internalLink: ({ mark, children }) => {
        // console.log(children, mark);
        return <Link to={_linkResolver(mark.reference)}>{children}</Link>;
      },
      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        // console.log(mark, children);
        const { href } = mark;
        console.log(href);
        return (
          <a href={href} target='_blank' rel='noopener, noreferrer'>
            {children}
          </a>
        );
        // return blank ? (
        //   <a href={href} target='_blank' rel='noopener, noreferrer'>
        //     {children}
        //   </a>
        // ) : (
        //   <a href={href}>{children}</a>
        // );
      },
    },
  };
  // console.log(input)
  return (
    <div className='texte'>
      <PortableText blocks={input} serializers={serializers} />
    </div>
  );
};

export default SanityTexte;
