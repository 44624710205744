import clsx from "clsx";
import React from "react";
import useLocale from "../../contexts/LocaleWrapper";
const locales = require("../../../config/i18n");

const LocaleSwitcher = () => {
  const { locale, dispatch } = useLocale();

  return (
    <ul className='locale-switcher'>
      {Object.values(locales).map((item, i) => (
        <li
          key={`locale-${i.toString()}`}
          className={i < Object.values(locales).length - 1 ? "mb-xs" : "mb-1e"}>
          <button
            onClick={() => dispatch(item.locale)}
            className={clsx(
              "outline py-sm px-md",
              locale === item.locale ? "is-active" : ""
            )}>
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default LocaleSwitcher;
