import React from "react";
import ReactCookieConsent from "react-cookie-consent";
import { _localizeField, _localizeText } from "../../core/utils";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SanityTexte from "../SanityTexte";
// import { _getLocalizedText } from '../../lib/helpers'
import { publish } from "pubsub-js";

const CookieConsent = () => {
  const { settings } = useSiteMetadata();
  // console.log(settings);
  return (
    <ReactCookieConsent
      // debug={true}
      containerClasses='cookie-consent text-sm md:text-base'
      contentClasses='cookie-consent--content'
      disableStyles={true}
      location='bottom'
      buttonText={_localizeText("cookieButtonText")}
      declineButtonText={_localizeText("cooieDeclineButtonText")}
      // cookieName='gatsby-gdpr-google-analytics'
      acceptOnScroll={false}
      onAccept={(acceptedByScrolling) => publish("COOKIE_CONSENT_ACCEPT")}>
      {/* <div
        dangerouslySetInnerHTML={{
          // __html: _localizeText("cookieMessage"),
          // __html: _localizeField(settings.messageCookie),
        }}
      /> */}
      <SanityTexte input={_localizeField(settings.messageCookie)} />
    </ReactCookieConsent>
  );
};

export default CookieConsent;
