import React from "react";
import clsx from "clsx";

const Credits = ({ showCredits }) => {
  return (
    <div
      className={clsx(
        "credits texte no-ma-pa mt-xs-",
        showCredits ? "block" : "hidden"
      )}>
      <p className='text-sm'>
        Concept and design by{" "}
        <a href='https://dom-bo.de' target='_blank' rel='noopener noreferrer'>
          dombek—bolay
        </a>{" "}
        &{" "}
        <a href='https://naasner.com' target='_blank' rel='noopener noreferrer'>
          Naasner Office
        </a>
        ,<br className='hidden-sm' />
        illustration by{" "}
        <a
          href='https://georgepopov.com'
          target='_blank'
          rel='noopener noreferrer'>
          George Popov
        </a>
        , development by{" "}
        <a
          href='https://ahmedghazi.com'
          target='_blank'
          rel='noopener noreferrer'>
          Ahmed Ghazi
        </a>
        .<br className='hidden-sm' />
        Running on{" "}
        <a
          href='https://www.gatsbyjs.com/'
          target='_blank'
          rel='noopener noreferrer'>
          Gatsby
        </a>
        ,{" "}
        <a
          href='https://www.sanity.io/'
          target='_blank'
          rel='noopener noreferrer'>
          Sanity
        </a>{" "}
        &{" "}
        <a
          href='https://www.netlify.com/'
          target='_blank'
          rel='noopener noreferrer'>
          Netlify
        </a>
        .
      </p>
    </div>
  );
};

export default Credits;
